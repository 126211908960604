.raffle {
  animation: spin 0.5s linear infinite;
}
.normal {
  animation: none;
}
.medium-raffle {
  animation: spin 2s linear infinite;
}
.slow-raffle {
  animation: spin 5s linear infinite;
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
